import { render, staticRenderFns } from "./WatchRedirection.vue?vue&type=template&id=19a27340&scoped=true&"
import script from "./WatchRedirection.vue?vue&type=script&lang=js&"
export * from "./WatchRedirection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19a27340",
  null
  
)

export default component.exports